body {
  background: url(bg_tile.png);
}

.icono + a {
  color: black;
  text-shadow: black 1px 1px;
  font-size: 2rem;
}

.retrato {
  position: relative;

  border: solid 0 transparent;
  border-radius: 100%;

  margin-left: auto;
  margin-right: auto;

  width: 30%;
  min-width: 200px;
  max-width: 500px;

  box-shadow: 0 2px 2px 0 gray, 0 0 0 10px white, 0 0 0 18px #f9a519,
    0 2px 10px 20px gray;
  z-index: 3;
  transform: scale(0.85) translatey(70%);
}

.contenedor {
  background-color: rgba(255, 255, 255, 0.9);
  position: relative;
  margin: auto auto 150px auto;
  width: 90%;
  box-shadow: 0 0 5px 5px rgba(155, 155, 155, 0.8);
}

.gradiente-horizontal {
  position: relative;
  width: 100%;
  height: 30px;
  background: linear-gradient(to right, #f4831f, #f9a519);
}

.gradiente-horizontal-intermedio {
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  height: 0.5rem;
  background: linear-gradient(to right, #f4831f, #f9a519);
}

#nombrePersonal {
  text-align: center;
  font-size: 3em;
}

#nombrePersonal.inicio {
  margin-top: 100px;
}

#primer-nombre {
  display: inline;
  color: black;
}

#primer-apellido {
  display: inline;
  color: #f4831f;
}

#logo {
  position: relative;
  width: 40%;
}

.presentacion {
  text-align: center !important;
  font-size: 2rem;
}

.presentacion > a {
  color: black;
}

.desktop {
  display: none;
}

h1.seccion {
  text-align: center;
  font-size: 4rem;
  margin: auto;
  text-transform: uppercase;
  font-weight: bold;
}

h1.seccion::first-letter {
  font-size: 1.5em;
}

img,
.img-resposive {
  max-width: 300px !important;
}

.form-name {
  margin: auto;
  text-transform: uppercase;
  font-weight: bold;
  color: #f4831f;
  margin-left: 10%;
}

.form-name::first-letter {
  font-size: 1.5em;
}

div.form-row {
  display: flex;
}

div.form-col > a > button {
  color: black;
  width: 90%;
  border: none;
  box-shadow: 2px 2px 0 0 #999, 0 2px 0 0 #999, 2px 0 0 0 #999,
    -2px -2px 0 0 #ddd, 0 -2px 0 0 #ddd, -2px 0 0 0 #ddd, 0 0 0 2px #ddd;
  text-transform: uppercase;
  font-weight: 600;
}

div.form-col > a > button::first-letter {
  font-size: 1.2em;
}

div.form-col > input {
  position: relative;
  right: 0;
  width: 80%;
}

div.form-col {
  flex-direction: column;
  flex: 1;
}

/* =================================
            SUBRAYADO
================================= */

span.cuadrito1,
span.cuadrito2 {
  border: solid 5px #f9a519;
}

span.cuadrito1 {
  left: 0;
}

span.cuadrito2 {
  right: 0;
}

.contacto > div {
  text-align: left;
  margin-right: 50px;
}

/* =================================
            ICONOS
================================= */

.icono {
  position: relative;
  margin: 5px;
  width: 20px;
  height: 20px;
}

.icono.ubicacion {
  width: 15px;
  margin-left: 8px;
  margin-right: 8px;
}

.icono.grande {
  width: 50px;
  height: 50px;
}

.icono.grande:hover {
  filter: sepia(1);
}

.icono.grande:active {
  filter: invert(100%);
}

.renglon-telefono,
.renglon-mobile,
.renglon-ubicacion,
.renglon-mail {
  margin-left: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.social-icons {
  position: relative;
  margin: 10%;
}

/* =================================
              QR
================================= */

.qr-button {
  position: fixed;
  top: 20%;
  right: 0;
  width: 50px;
  height: 50px;
  background: white;
  border: solid 2px black;
  border-radius: 100%;
}

.qr-button:hover {
  transform: scale(1.1);
}

.qr-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  background: black;
  pointer-events: none;
  opacity: 0;
  transform: scale(0.5);
}

#qr-toggle:checked ~ .qr-overlay {
  pointer-events: auto;
  opacity: 0.9;
  transform: scale(1);
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.qr-image {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  width: 100%;
  max-width: 640px;
  pointer-events: none;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.5);
}

#qr-toggle:checked ~ label > .qr-image {
  pointer-events: auto;
  opacity: 1;
  transform: translate(-50%, -50%) scale(0.9);
  transition: transform 0.5s ease, opacity 0.5s ease;
  transition-delay: 0.1s;
}

/* =================================
            NAVBAR
================================= */

.testing-navbar {
  border: none;
  margin: 0;
}

.container {
  width: 100%;
}

.navbar-collapse {
  background: linear-gradient(to top right, #d2d2d2 0%, #ebebeb 100%);
}

.navbar-inverse .navbar-nav > li > a {
  color: black;
  font-size: 1.5em;
}

.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li > a:focus {
  color: #f4831f;
}

.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus {
  background-color: #f9a519;
}

/* =================================
            WIDTH
================================= */

.width-70 {
  width: 70%;
}

.centrado {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

/* =================================
            Perfil
            Giro
            Productos
            Servicios
            MiPanel
            Reconocimientos
            Clientes
            Proyectos
            Testimonios
            Galeria
================================= */

h1.perfil,
h1.giro,
h1.productos,
h1.servicios,
.proyectos > h3,
.testimonios > h3,
.linea2 {
  color: #f4831f;
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  text-transform: uppercase;
}

h1.perfil::first-letter,
h1.giro::first-letter,
h1.productos::first-letter,
h1.servicios::first-letter,
.proyectos > h3::first-letter,
.testimonios > h3::first-letter {
  font-size: 1.5em;
}

div[class*="-row"] {
  margin: 20px;
}

.miPanel > h3,
p.linea1,
.fecha {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.miPanel > h3::first-letter,
.proyectos > h3::first-letter {
  font-size: 1.2em;
}

.proyectos > h3,
.testimonios > h3,
p.linea1 {
  font-style: italic;
}

p.testimonio {
  font-style: italic;
  text-align: center;
}

p.descripcion {
  font-style: italic;
  text-align: center;
  font-size: 1.3em;
  font-weight: 600;
}

div.reconocimientos-col > div.linea2 {
  background: #999;
}

div.reconocimientos-col > div.fecha {
  background: #bbb;
}

div.reconocimientos-row {
  background: #bbb;
}

.clientes,
.galeria {
  display: flex;
  flex-wrap: wrap;
}

.clientes > div,
.galeria > div {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.clientes > div > a > img,
.galeria > div > img {
  margin: 10px;
}

/* =================================
                MAP
================================= */
.mapa {
  position: relative;
  margin: auto;

  margin-bottom: 20px;
  margin-top: 20px;
  border: solid white 5px;
  box-shadow: 0 0 5px 5px rgba(155, 155, 155, 0.5);
}

/* =================================
            Non-Desktop
================================= */

@media screen and (max-width: 1279px) {
  .testing-navbar {
    border: none;
    margin: 0;
    background: rgba(255, 255, 255, 0);
  }

  .navbar-toggle + .navbar-collapse.collapse.in {
    display: block;
  }

  .navbar-toggle.collapsed + .navbar-collapse.collapse {
    display: none;
  }

  .navbar-collapse > ul.nav.navbar-nav > li {
    float: none;
  }

  .navbar-toggle,
  .navbar-toggle:active {
    display: block;
    background: #f4831f !important;
  }

  .navbar-toggle:hover {
    background: #f9a519 !important;
  }

  .contenedor {
    top: 100px;
  }

  .hemi + .contenedor {
    top: 0;
  }

  .non-desktop-gray-gradient {
    margin-top: 5px !important;
    background: linear-gradient(to top right, #d2d2d2 50%, #ebebeb 70%);
  }

  .miPanel-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .panelPar {
    width: 100%;
    border: solid 0 #d2d2d2;
    border-top-width: 5px;
    border-bottom-width: 5px;
    background: #d2d2d2;
  }
}

/* =================================
              TABLET
================================= */

@media screen and (min-width: 768px) {
  #nombrePersonal.inicio {
    margin-top: 170px;
  }

  .gradiente-horizontal {
    height: 30px;
  }
}
/* =================================
              DESKTOP
================================= */
@media screen and (min-width: 1280px) {
  .desktop {
    display: inherit;
  }

  .app {
    position: relative;
    top: 100px;
  }

  .contenedor {
    margin-left: 100px;
    margin-right: 100px;
    width: auto;
    height: 50%;
    position: relative;
    overflow: auto;
  }

  .hemi + .contenedor {
    margin-left: 250px;
  }

  .nombreLogoContacto {
    border: solid 50px transparent;
    display: flex;
    flex-direction: row;
  }

  .gradiente-horizontal {
    height: 30px;
  }

  .hide-in-desktop {
    display: none;
  }

  .retrato {
    position: absolute;

    top: 50%;
    left: 50px;
    width: 300px;
    transform: translate(0, -50%);
  }

  .hemi {
    position: absolute;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.9) 50%,
      rgba(255, 255, 255, 0) 50%
    );
    border: solid 0 transparent;
    border-radius: 100%;
    height: 400px;
    width: 400px;
    top: 50%;
    left: 50px;
    transform: translate(0, -50%);
    z-index: 5;
    box-shadow: -4px 0 1px 0 rgba(155, 155, 155, 0.5);
  }

  #nombrePersonal.inicio {
    margin-top: 50px;
  }

  .nombreYlogo {
    flex: 1.5;
  }

  .contacto {
    flex: 1;
  }

  .col {
    flex: 0.3;
  }

  #logo {
    width: 100%;
    max-width: 300px;
  }

  .mapa {
    max-width: 600px !important;
  }

  .social-icons {
    margin: 0;
  }

  div.social-icons a {
    flex: 1;
  }

  .testing-navbar {
    position: relative;
    border: none;
    margin: 0;
  }

  .container {
    width: 100%;
    background: linear-gradient(to top right, #d2d2d2 0%, #ebebeb 100%);
  }

  div[class*="-row"] {
    display: flex;
  }

  div[class*="-col"] {
    margin: 10px;
    flex-direction: column;
    flex: 1;
  }

  div.miPanel {
    background: #d2d2d2;
    border: solid 5px #d2d2d2;
  }

  .reconocimientos-row {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 50%;
  }
  .reconocimientos-col {
    margin: 0 !important;
  }

  .reconocimientos-col > .linea2,
  .reconocimientos-col > .fecha {
    height: 200px;
    font-size: x-large;
    margin: auto;
    padding: 50px;
  }
}
