.slogan {
  display: flex;
  width: 300px;
  margin: auto;
  margin-top: 20px;
  text-align: center;
  flex-direction: column;
}

.p-inicio {
  max-width: 600px;
  min-width: 200px;
  text-align: center;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

h3.servicios {
  color: #f6841f;
}

.servicios > h3 {
  color: #f4831f;
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  /* text-transform: uppercase; */
}

.servicios-col {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  text-align: justify;
}

.primeraLetra {
  display: inline;
  font-size: 1.4em;
}

.titulos-tarjetas > h3 {
  text-align: center;
  color: #4c4d4f;
}

.titulos-tarjetas-col,
.titulos-tarjetas-row {
  margin: 0px !important;
  padding: 0px !important;
}

.tarjetas-row,
.tarjetas-col {
  margin-top: 0px !important;
}

.tarjetas-col {
  padding-right: 50px;
}

/* .tarjetas-col:nth-of-type(1),
.tarjetas-col:nth-of-type(3) {
  background-color: #e6e7e8;
} */

.tarjetas-col:nth-of-type(2n + 1) {
  background-color: #e6e7e8;
}

.tarjetas-col > h3 {
  margin-left: 15px;
}

.card-specs {
  text-align: justify;
}

.omg-test {
  background-color: #f00;
  display: flex;
  flex-direction: column;
  flex: 1;
}

[class*="naranja"] {
  color: #f4831f;
  display: inline;
}

[class*="italica"] {
  font-style: italic;
}

[class*="bold"] {
  font-weight: bold;
}

.webAppProgresiva {
  width: 80%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #e7e6e3;
}

.caracteristicas {
  margin: auto;
  width: 70%;
  margin-bottom: 50px;
}

.caracteristicas > p > div {
  display: inline;
  color: #f4831f;
  font-weight: bold;
}

.contactanos {
  font-size: 2em;
  width: 60%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #e7e6e3;
  text-align: center;
}

.mailContacto {
  text-align: center;
  font-size: 3rem;
}

.logoH {
  text-align: right;
}
/**********************************
*                                 *
*                                 *
*            zeplin.io            *
*                                 *
*                                 *
***********************************/
.Rectangle-2-copy-4 {
  background-color: #e6e7e8;
}

.Todo {
  margin-left: 50px;
  font-size: 2em;
  font-weight: bold;
  text-align: left;
  color: #f6841f;
}

.lo-que-necesita-tu-negocio {
  font-size: 1.3em;
  font-weight: bold;
  text-align: justify;
  color: #949494;
}

.para-ser-un-xito {
  font-size: 1.6em;
  font-weight: bold;
  text-align: right;
  color: #58595b;
  display: inline;
}

.para-ser-un-xito.text-style-1 {
  color: #f6841f;
}

.align-right {
  margin-right: -50px;
}

.webResponsiba {
  font-size: 2em;
  text-align: center;
  color: #949494;
}

.webResponsiba > div {
  color: #f6841f;
  font-size: 1.1em;
  font-weight: 600;
  display: inline;
}
